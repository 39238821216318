import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import Card from "../../../components/Atoms/Cards/Card";
import React, { useEffect } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useCourse } from "../../../hooks/CourseHook";
import { TextData } from "./data";
import { Routes } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

export const Invited = () => {
  const { getMyCourseData } = useCourse();
  const navigate = useNavigate();
  const myCourseList = useSelector(
    (state) => state?.myCourseReducer?.invitedCourse?.data?.data
  );
  const companyName = useSelector(
    (state) => state?.myCourseReducer?.invitedCourse?.companyName
  );
  const userDetail = useSelector((state) => state?.authReducer?.user);
  const firstName = userDetail?.firstName ? userDetail?.firstName : "";
  const lastName = userDetail?.lastName ? userDetail?.lastName : "";
  const username = firstName + " " + lastName;

  useEffect(() => {
    getMyCourseData();
  }, []);

  const handleStartCourse = (
    courseId,
    alreadyPrequestioner,
    title,
    slug,
    prequestionerStatus
  ) => {
    if (alreadyPrequestioner === 0) {
      navigate(Routes.preCourseInvited, {
        state: { courseId: courseId, companyName: companyName },
      });
    } else {
      if (prequestionerStatus === "Accept") {
        navigate(Routes?.aboutTrainingCourse.replace(":id", slug), {
          state: {
            courseId: courseId,
            title: title,
            username: username,
            slug: slug,
          },
        });
      } else {
        message.error("Please Contact to Admin");
      }
    }
  };

  return (
    <>
      <Styled.Header>
        <div className="invitedText">{TextData.invitedCourses}</div>
        <div className="text">{TextData.text}</div>
      </Styled.Header>
      <Styled.CardBox>
        {myCourseList?.map((item) => {
          return (
            <>
              <Card
                data={item?.Course}
                children={
                  <Styled.StartButton>
                    <Button
                      className="start"
                      title={"START COURSE"}
                      icon={<BiSolidRightArrow />}
                      onClick={() =>
                        handleStartCourse(
                          item?.courseId,
                          item?.alreadyPrequestioner,
                          item?.Course?.title,
                          item?.Course?.slug,
                          item?.prequestionerStatus
                        )
                      }
                    />
                  </Styled.StartButton>
                }
              />
            </>
          );
        })}
      </Styled.CardBox>
    </>
  );
};
