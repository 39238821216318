import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import { Checkbox, message } from "antd";
import { exam } from "../ExamCourse/data";
import { induction } from "./data";
import useInductionHook from "../../../hooks/InductionHook";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";
const InductionCheckData = ({ location }) => {
  const { getChecklistData, handlePostChecklist } = useInductionHook();
  const [postListData, setPostListData] = useState([]);
  const [parentCheckboxState, setParentCheckboxState] = useState({});
  const checklistData = useSelector(
    (state) => state?.inductionReducer?.checklist
  );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const response = await handlePostChecklist(postListData);
    if (response?.status === 200) {
      message.success(response?.data?.message);
      navigate(Routes?.courseCompletion, { state: location?.state?.title });
    }
    return response;
  };

  const handleTickAll = (questionId, e, questionIndex) => {
    const isChecked = e.target.checked;
    const childrenAnswers =
      checklistData[questionIndex]?.children?.map((child) => child?.id) || [];

    setPostListData((prevData) => {
      let updatedData = prevData?.filter(
        (data) => data?.question_id !== questionId
      );

      if (isChecked) {
        updatedData.push({
          courseId: location?.state?.courseId,
          question_id: questionId,
          answers_id: childrenAnswers,
        });
      }
      return updatedData;
    });
  };

  const handleChange = (answerId, e, questionIndex) => {
    const questionId = checklistData[questionIndex]?.id;
    setPostListData((prevData) => {
      let updatedData = [...prevData];
      const questionIndexInData = updatedData?.findIndex(
        (data) => data?.question_id === questionId
      );

      if (questionIndexInData === -1) {
        updatedData.push({
          courseId: location?.state?.courseId,
          question_id: questionId,
          answers_id: [answerId],
        });
      } else {
        let questionData = updatedData[questionIndexInData];
        if (e.target.checked) {
          if (!questionData.answers_id?.includes(answerId)) {
            questionData?.answers_id?.push(answerId);
          }
        } else {
          questionData.answers_id = questionData?.answers_id?.filter(
            (id) => id !== answerId
          );
        }
        updatedData[questionIndexInData] = questionData;
      }

      return updatedData;
    });
  };

  useEffect(() => {
    const newParentCheckboxState = {};

    checklistData &&
      checklistData?.forEach((item) => {
        const childrenIds = item?.children?.map((child) => child?.id) || [];
        const questionData = postListData?.find(
          (data) => data?.question_id === item?.id
        );
        const allChildrenChecked = childrenIds?.every((id) =>
          questionData?.answers_id.includes(id)
        );

        newParentCheckboxState[item?.id] = {
          checked: allChildrenChecked,
        };
      });

    setParentCheckboxState(newParentCheckboxState);
  }, [postListData, checklistData]);

  useEffect(() => {
    getChecklistData();
  }, []);

  return (
    <>
      <Styled.ExamData>
        <div className="exam">
          {induction.induction}
          <span className="form">{induction.check}</span>
        </div>
        <Styled.Security>
          <div>
            <div className="name">
              {exam.name}
              <span className="title">{location?.state?.username}</span>
            </div>
            <div className="name">
              {exam.title}
              <span className="title">{location?.state?.title}</span>
            </div>
          </div>
        </Styled.Security>
        <div className="how">{induction?.boxes}</div>
        {checklistData &&
          checklistData?.map((item, index) => (
            <Styled.Tables key={item?.question.split(" ").join("") + index}>
              <table style={{ width: "651px" }} className="tableData">
                <colgroup>
                  <col span="1" style={{ width: "87%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>{item?.question}</th>
                    <th className="column-style">
                      <Checkbox
                        checked={parentCheckboxState[item.id]?.checked || false}
                        onChange={(e) => handleTickAll(item?.id, e, index)}
                      />
                      <div className="tick">{induction.tick}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item?.children &&
                    item?.children?.map((child, ind) => (
                      <tr key={child?.question.split(" ").join("") + ind}>
                        <td>{child?.question}</td>
                        <td className="column-style">
                          <Checkbox
                            checked={postListData.some(
                              (data) =>
                                data?.question_id === item?.id &&
                                data?.answers_id.includes(child?.id)
                            )}
                            onChange={(e) => handleChange(child?.id, e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Styled.Tables>
          ))}
        <Styled.Acknowledge>
          <div className="ackno">
            <div className="train">{induction.trainee}</div>
            <div className="cover">{induction.covering}</div>
          </div>
          <div>
            <div className="comment">{induction.comm}</div>
            <Styled.Text />
            <Styled.Line />
            <Styled.Sign>
              <div className="signs">
                {induction.sign}
                <Styled.Input type="text" />
              </div>
              <div className="datesS">
                {induction.dateS}
                <Styled.InputDate type="text" placeholder="DD/MM/YYYY" />
              </div>
            </Styled.Sign>
          </div>
        </Styled.Acknowledge>
        <div className="secured">
          <Button
            title={"Submit Checklist"}
            className="start"
            onClick={() => handleSubmit()}
          />
        </div>
      </Styled.ExamData>
    </>
  );
};

export default InductionCheckData;
