import * as Styled from "./style";
import Button from "../Button/button";
import General_Security_bg from "../../../assets/Svg/General_Security_bg.svg";
import React from "react";
import Security_check from "../../../assets/Svg/Security_check.svg";
import useCartHook from "../../../hooks/CartHook";
import useCommonHook from "../../../hooks/CommonHook";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdAdd, MdOutlineHorizontalRule } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { constant } from "../../../constants/Constants";
import { useCounter } from "../../../hooks/Counter";
import { Routes } from "../../../routes/routes";
import { color } from "../../../theme/Color";
import { message } from "antd";

export const GeneralSecurity = ({
  title,
  price,
  courseId,
  alreadyPurchage,
  purchageDate,
  preCourseFilled,
  slug,
  declarationFormApproved,
  courseType,
  prequestionerStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCommonState } = useCommonHook();
  const { count, setCount, handleDecrement, handleIncrement } = useCounter();
  const headerData = useSelector((state) => state.authReducer.user);

  const user_name = headerData?.firstName + " " + headerData?.lastName;
  const { handleCart, cartAction } = useCartHook();
  const sessionId = useSelector((state) => state?.commonReducer.sessionId);
  const cartItem = {
    title,
    price: parseInt(price) * count,
    courseId,
    userId: headerData?.id ? headerData?.id : 0,
    sessionId,
    qty: count,
  };

  const numberOnly = (e) => {
    if (e.target.value > 0) {
      setCount(e.target.value);
    } else {
      setCount("");
    }
  };

  const handleRoutes = async (cartData) => {
    if (headerData) {
      navigate(Routes?.multiCart);
    } else {
      updateCommonState({
        loginReferer: "cart",
      });
      navigate(Routes?.askingPage);
    }
    const cart = await dispatch(cartAction(cartData));
    return cart;
  };

  const handleCourse = () => {
    if (preCourseFilled) {
      if (prequestionerStatus === "Accept") {
        navigate(Routes?.aboutTrainingCourse.replace(":id", slug), {
          state: {
            title: cartItem?.title,
            username: user_name,
            slug: slug,
            courseId: courseId,
          },
        });
      } else {
        message.error("Please Contact to Admin");
      }
    } else {
      if (declarationFormApproved === 0 && courseType === "AVSEC") {
        message.error("Please contact to Admin");
      } else {
        if (headerData?.parentId == 0) {
          navigate(Routes?.singlePreCourse, {
            state: {
              title: cartItem?.title,
              courseId: courseId,
            },
          });
        } else {
          navigate(Routes?.preCourseInvited, {
            state: {
              title: cartItem?.title,
              courseId: courseId,
              companyName: headerData?.parentUserData?.companyName,
            },
          });
        }
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.BGImage src={General_Security_bg}>
        <Styled.SecurityCheck>
          <Styled.CenterImage>
            <img src={Security_check} alt="Security_check" />
          </Styled.CenterImage>
          <Styled.FirstRow>{title}</Styled.FirstRow>
          <Styled.SecondRow>
            <Styled.Icon>
              <span
                dangerouslySetInnerHTML={{ __html: "&#163;" }}
                className="code"
              ></span>
              {price}
            </Styled.Icon>
            {headerData?.type === "Corporate User" ? (
              <Styled.ButttonContainer>
                <Button
                  className="decrement"
                  title={<MdOutlineHorizontalRule className="decrementIcon" />}
                  onClick={handleDecrement}
                />
                <Styled.Input
                  type="number"
                  required
                  minlength="1"
                  min="1"
                  step={1}
                  className="count"
                  value={count}
                  pattern="\d+"
                  onChange={numberOnly}
                />
                <Button
                  className="add_"
                  title={<MdAdd className="decrementIcon" />}
                  onClick={handleIncrement}
                />
              </Styled.ButttonContainer>
            ) : null}
          </Styled.SecondRow>
          <Styled.Buttons>
            {alreadyPurchage ? (
              <>
                <div
                  style={{ color: "white" }}
                >{`You enrolled in this course on ${purchageDate}`}</div>
                <Styled.MainContainer>
                  <Button
                    className="add_to_cart"
                    title={constant.Go_to_course}
                    onClick={() => handleCourse()}
                    disabled={count < 1}
                  />
                </Styled.MainContainer>
              </>
            ) : (
              <>
                <Styled.MainContainer>
                  <Button
                    className="add_to_cart"
                    title={constant.Add_to_cart_button}
                    icon={<AiOutlineArrowRight />}
                    onClick={() => handleCart(cartItem)}
                    navigate
                    disabled={count < 1}
                  />
                </Styled.MainContainer>
                <Styled.Enroll>
                  <Button
                    className="enroll"
                    title={constant.Enroll_button}
                    onClick={() => handleRoutes(cartItem)}
                    disabled={count < 1}
                  />
                </Styled.Enroll>
              </>
            )}
          </Styled.Buttons>
        </Styled.SecurityCheck>
      </Styled.BGImage>
    </Styled.Container>
  );
};
